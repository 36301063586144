import { AttyHelper } from '../attributes/AttyHelper.js';
import { PolyluxGrid } from '../grid/PolyluxGrid.js';

class PollyluxDemoGrid {

	_wrapperElement;

	_attributes;

	_viewLineElement;

	_gridElement;

	_gridIsDrawn = false;

	_gridDisplay;

	constructor(wrapperElement, attributes){

		this._wrapperElement = wrapperElement;

		this._attributes = attributes;
	}

	redraw(gridElements, pixelIndex, stepIndex){

		this.drawGrid(gridElements, pixelIndex, stepIndex);
	}

	drawViewline(){

		//trace("drawViewline");
		if(this._viewLineElement == null){
			this._viewLineElement = window.document.createElement("div");
			this._viewLineElement.classList.add("polylux-demo-viewline");
			this._viewLineElement.style.position = "fixed";
			this._viewLineElement.style.left = "0";
			this._viewLineElement.style.height = "1px";
			this._viewLineElement.style.width = "100%";
			this._viewLineElement.style.zIndex = "99999999999";
			this._viewLineElement.style.borderBottom = "1px solid " + this._attributes.demoColor;
			this._viewLineElement.style.transform = "translateY(-4px)";
			window.document.body.appendChild(this._viewLineElement);
		}
		this._viewLineElement.style.top = (this._attributes.lineOffset * 100) + "%";

	}

	drawGrid(gridElements, pixelIndex, stepIndex){

		//trace("drawGrid");
		if(this._gridIsDrawn == false){
			this._gridElement = window.document.createElement("div");
			this._gridElement.classList.add("polylux-demo-grid");
			this._gridElement.style.position = "absolute";
			this._gridElement.style.top = "0";
			this._gridElement.style.left = "0";
			this._gridElement.style.zIndex = "9999999";
			this._gridElement.style.width = "100%";
			this._gridIsDrawn = true;
		}

		this._gridElement.innerHTML = "";

		this._gridDisplay = window.document.createElement("div");
		this._gridDisplay.classList.add("polylux-demo-grid-display");
		this._gridDisplay.style.position = "fixed";
		this._gridDisplay.style.bottom = "0";
		this._gridDisplay.style.left = "0";
		this._gridDisplay.style.backgroundColor = "#fff";
		this._gridDisplay.style.padding = "8px 16px 0px 20px";
		this._gridDisplay.style.display = "inline-block";
		this._gridDisplay.style.minWidth = "140px";
		this._gridDisplay.style.zIndex = "99999999";
        this._gridDisplay.style.color = "#333";
		this._gridDisplay.style.border = "1px solid " + this._attributes.demoColor;
		this._gridDisplay.innerHTML = "<div><p>StepIndex: " + stepIndex + "<br/>PixelIndex: " + pixelIndex + "</p></div>";

		this._gridElement.appendChild(this._gridDisplay);

		for(var i = 0; i < gridElements.length; i++){

			//trace("-----");
			var tempGridItem = window.document.createElement("div");
			tempGridItem.classList.add("polylux-demo-grid-item");
			tempGridItem.style.opacity = "0.4";
			tempGridItem.style.borderTop = "1px solid " + this._attributes.demoColor;

			if(i % 2 != 0){

				tempGridItem.style.backgroundColor = "#ccc";
			}else{
				tempGridItem.style.backgroundColor = "#999";
			}

			//trace("gridElements[i].from: " + gridElements[i].from);
			//trace("gridElements[i].to: " + gridElements[i].to);


			var tempHeight = (gridElements[i].to - gridElements[i].from);
			//trace(tempHeight);
			tempGridItem.style.height = (tempHeight - 0.1) + "px";

			this._gridElement.appendChild(tempGridItem);
		}

		this._wrapperElement.appendChild(this._gridElement);

	}

}

export {PollyluxDemoGrid};