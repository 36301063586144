class MobileNav {

    /**
     * @type {HTMLElement}
     */
    _moduleWrapper = null;

    /**
     * @type {HTMLElement}
     */
     _overlayElement = null;

    /**
     * @type {HTMLButtonElement}
     */
    _openButton = null;

    /**
     * @type {HTMLButtonElement}
     */
    _closeButton = null;

    constructor(moduleWrapper){

        this._moduleWrapper = moduleWrapper;

        this._openButton = this._moduleWrapper.querySelector(".button");
        this._openButton.addEventListener("click", (ev)=>{this._openHandler(ev);});

        this._overlayElement = document.querySelector(".mobile-overlay");

        this._closeButton = this._overlayElement.querySelector(".button");
        this._closeButton.addEventListener("click", (ev)=>{this._closeHandler(ev);});
    }

    _openHandler(ev){

        console.log("_openHandler");
        this.open();
    }

    _closeHandler(ev){

        console.log("_closeHandler");
        this.close();
    }

    open(){

        this._overlayElement.classList.add("open");
    }



    close(){

        this._overlayElement.classList.remove("open");
    }
}

export {MobileNav};