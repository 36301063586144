import { PolyluxError } from '../error/PolyluxError.js';

class PolyluxEventManager{

    _eventPixelChange = [];

    _eventStepChange = [];

    constructor() {

    }

    addEventListener(eventName, func){

        //console.log("2* Event Name", eventName);


        switch(eventName){

            case "pixelchange":
                //console.log("3* Polyluxeventmanager pixelchange");
                this.registerEvent(eventName, func, this._eventPixelChange);
                break;

            case "stepchange":
                //console.log("3* Polyluxeventmanager stepchange");
                this.registerEvent(eventName, func, this._eventStepChange);
                break;

            default:
                //console.log("3* Polyluxeventmanager Error");
                PolyluxError.display("Add Eventlistener - Event name '" + eventName + "' is unknown.");
                break;
        }
        //console.log("-------------------------------------------------");

    }

    removeEventListener(eventName, func){

        switch(eventName){

            case "pixelchange":
                this.unregisterEvent(eventName, func, this._eventPixelChange);

            case "stepchange":
                this.unregisterEvent(eventName, func, this._eventStepChange);

            default:
                PolyluxError.display("Remove Eventlistener - Event name '" + eventName + "' is unknown.");
        }
    }

    unregisterEvent(eventName, func, storage){

        var index = storage.indexOf(func);

        if(index > -1){
            storage.splice(index, 1);
        }else{
            PolyluxError.display("Can not remove function from Event name '" + eventName + "'. The function is unknown.");
        }
    }

    registerEvent(eventName, func, storage){

        var index = storage.indexOf(func);

        if(index < 0){
            storage.push(func);
        }else{
            storage[index] = func;
        }
    }

    executeEvent(eventName, data){

        switch(eventName){

            case "pixelchange":
                this.executionLoopEvent(data, this._eventPixelChange);

            case "stepchange":
                this.executionLoopEvent(data, this._eventStepChange);
        }
    }

    executionLoopEvent(data, storage){

        for (let i = 0; i < storage.length; i++) {
            storage[i](data);
        }
    }

    // GETTER SETTER

    get eventPixelChange() {
        return this._eventPixelChange;
    }

    get eventStepChange() {
        return this._eventStepChange;
    }
}

export {PolyluxEventManager};