import { PolyluxSection } from './PolyluxSection.js';

class Polylux {

    _sections = [];

	_bussy = false;

    constructor(){

        window.addEventListener("scroll", ()=>{this._scrollHandler();});
		window.addEventListener("resize", ()=>{this._scrollHandler();});
        this._frameLoopHandler();
	}

	_resizeHandler(){

		this._scrollHandler();
	}

	_scrollHandler(){

		if(this._bussy == false){

			window.requestAnimationFrame(()=>{this._frameLoopHandler();});
        }
		this._bussy = true;
	}

    _frameLoopHandler(){

        for(var i = 0; i < this._sections.length; i++){

            this._sections[i].execute();
            //trace("executing: " + _sections[i].id);
        }

        this._bussy = false;
    }

    addSection(moduleElement, options = null){

        var tempSection = new PolyluxSection(moduleElement, this._sections.length, options);
        this._sections.push(tempSection);
        this._resizeHandler();
        return tempSection;
    }
}

export {Polylux};