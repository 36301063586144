class PolyluxGrid {

	_id;

	_from;

	_to;

	_active = false;

	_distance;

	constructor(from, to, id){

		this._from = from;
		this._to = to;
		this._id = id;

		this._distance = this._to - this._from;
	}

	checkIfActive(scrollYPos){

		if(scrollYPos >= this._from && scrollYPos <= this._to){
			this._active = true;
		}else{
			this._active = false;
		}
		//trace(scrollYPos + " >= " + _from + " && " + scrollYPos + " <= " + _to);
		return this._active;
	}

	get id(){
		return this._id;
	}

	get from(){
		return this._from;
	}

	set from(value){
		this._from = value;
	}

	get to(){
		return this._to;
	}

	set to(value){
		this._to = value;
	}

	get distance(){
		return this._distance;
	}
}

export {PolyluxGrid};