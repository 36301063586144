import {PolyluxSection} from '../PolyluxSection.js';

class PolyluxStepEvent {

    actualStep = 1;

    totalSteps;

    activeState = -1;

    constructor(actual, total, state){

        this.actualStep = actual;
        this.totalSteps = total;
        this.activeState = state;
    }

}

export {PolyluxStepEvent};