import {Polylux} from "./../polylux/Polylux.js";
import {PolyluxSection} from "./../polylux/PolyluxSection.js";
import {PolyluxPixelEvent} from "./../polylux/event/PolyluxPixelEvent.js";

class HeaderHide {

    /**
     * @type {HTMLElement}
     */
    _moduleWrapper = null;

    /**
     * @type {Polylux}
     */
     _polylux = null;

    /**
    * @type {PolyluxSection}
    */
    _polyluxSection = null;

    constructor(moduleWrapper, polylux){

        this._moduleWrapper = moduleWrapper;

        this._polylux = polylux;

        let options = {
            prefix: "step",
            steps: 10,
            viewOffsetXs: 0,
            viewOffsetSm: 0,
            viewOffsetMd: 0,
            viewOffsetLg: 0,
            classadd: "append",
            ease: "linear",
            issilent: true
        };

        this._polyluxSection = this._polylux.addSection(document.body, options);

        this._polyluxSection.addEventListener("pixelchange", (ev)=>{this.pixelChangeHAndler(ev);})
    }

    /**
     *
     * @param {PolyluxPixelEvent} ev
     */
    pixelChangeHAndler(ev){

        console.log(ev);

        if(ev.actualPixel > 250){

            this.hide();
        }else{

            this.show();
        }
    }

    hide(){

        this._moduleWrapper.classList.add("out");
    }

    show(){

        this._moduleWrapper.classList.remove("out");
    }

}

export {HeaderHide};