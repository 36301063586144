import {PolyluxSection} from '../PolyluxSection.js';

class PolyluxPixelEvent {

    actualPixel = 0;

    totalPixel;

    activeState = -1;

    constructor(actual, total, state){

        this.actualPixel = actual;
        this.totalPixel = total;
        this.activeState = state;
    }

}

export {PolyluxPixelEvent};