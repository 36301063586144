class PolyluxError {

	static _prefix = "polyluX Error";

	constructor(){}

	static display(msg){

		throw new Error(this._prefix + " - " + msg);
	}

}

export {PolyluxError};