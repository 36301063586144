class DOMClassHander{

    _baseDOMClasses;

    constructor(baseDOMClasses){

        this._baseDOMClasses = baseDOMClasses;
    }

    add(element, classNames){

        this.reset(element);

		for (let i = 0; i < classNames.length; i++) {
            element.classList.add(classNames[i]);
        }

    }

    reset(element){

        element.setAttribute("class", this._baseDOMClasses);
    }

    append(element, prefix, stepIndex, steps, lastClass = null){

        this.reset(element);

        for (let i = 0; i < steps; i++) {

            if(i <= stepIndex){
                element.classList.add(prefix + "-" + (i + 1));
            }
        }

		if(lastClass != null){
			element.classList.add(lastClass);
		}
    }
}

export {DOMClassHander};