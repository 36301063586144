import { AttyHelper } from './attributes/AttyHelper.js';
import { PollyluxDemoGrid } from './demo/PollyluxDemoGrid.js';
import { DOMClassHander } from './dom/DOMClassHander.js';
import { PolyluxPixelEvent } from './event/PolyluxPixelEvent.js';
import { PolyluxStepEvent } from './event/PolyluxStepEvent.js';
import { PolyluxGrid } from './grid/PolyluxGrid.js';
import { PolyluxEasing } from './easing/PolyluxEasing.js';
import { PolyluxEventManager } from './event/PolyluxEventManager.js';

class PolyluxSection {

    _id;

	_moduleElement;

	_moduleClasses;

	_startYPos;

	_endYPos;

	_inView = null;

    _isInViewPort = false;

	_viewLine = 0;

	_atties;

	_stepIndex = 1;

    _pixelIndex = 0;

	_gridElements = [];

	_easing;

	_gridMeassurements = [];

    _domClassHandler;

    _passedOnce = false;

    _viewportHeightXS = 640;

    _viewportHeightSM = 768;

    _viewportHeightLG = 1080;

    _lastWindowYpos;

    _lastStep;

	_demoGrid;

    _state = -1;

    _initialPass = null;

    // Events

	_eventManager;

	constructor(moduleElement, int, options = null){

        this._moduleElement = moduleElement;

        this._id = "polyluxsection-" + int;

        //_moduleElement.id = _id;

        this._eventManager = new PolyluxEventManager();

		this._moduleClasses = this._moduleElement.getAttribute("class");

        this._domClassHandler = new DOMClassHander(this._moduleClasses);

		this._atties = new AttyHelper(this._moduleElement);

		if(options != null){
			this._atties.setValuesFromOptions(options);
        }

		this._easing = new PolyluxEasing(this._atties.innerSteps, 0, 0);

		if(this._atties.isDemo == true){
			this._demoGrid = new PollyluxDemoGrid(moduleElement, this._atties);
		}

		//trace(_atties);
	}

	execute(){

        // GET START AND END POS OF SECTION IN PIXEL
		var lineOffset = 0;

        // Bis 768
        if(window.innerHeight < this._viewportHeightXS){

            lineOffset = this._atties.viewLineOffsetXs;

        }else if(window.innerHeight < this._viewportHeightSM){

            lineOffset = this._atties.viewLineOffsetSm;

        }else if(window.innerHeight < this._viewportHeightLG){

            lineOffset = this._atties.viewLineOffsetMd;

        }else if(window.innerHeight >= this._viewportHeightLG){

            lineOffset = this._atties.viewLineOffsetLg;
        }

		this._atties.lineOffset = lineOffset;
		if(this._atties.isDemo == true){
			this._demoGrid.drawViewline();
		}

        //trace("Browser.window.innerWidth: " + Browser.window.innerWidth);
        //trace("lineOffset: " + lineOffset);
        //trace("Browser.window.pageYOffset: " + Browser.window.pageYOffset);
        //trace("Browser.window.innerHeight: " + Browser.window.innerHeight);

        this._viewLine = window.pageYOffset + (window.innerHeight*lineOffset);
		this._startYPos = this.offsetTop(this._moduleElement);
		this._endYPos = this._startYPos + this._moduleElement.offsetHeight;

		this._easing.start = this._startYPos;
		this._easing.end = this._endYPos;

		//trace(_easing);

        //trace("_viewLine: " + _viewLine);
        //trace("_startYPos: " + _startYPos);
        //trace("_endYPos: " + _endYPos);

		// SET UP THE GRID
		this._gridElements = [];
		for (var i = 0; i < this._atties.innerSteps; i++){
			var from = 0;
			var to = 0;

			if(this._gridElements.length > 0){
				from = (this._gridElements[i - 1].to) + 1;
			}else{
				from = this._startYPos;
			}

			switch(this._atties.easing){
				case "linear":

					if (i == (this._atties.innerSteps - 1)){
						to = from + this._easing.linear(i) - 1;
					}else{
						to = from + this._easing.linear(i);
					}

				case "easeOut":

					if(i == (this._atties.innerSteps - 1)){
						to = from + this._easing.easeOut(i) - 1;
					}else{
						to = from + this._easing.easeOut(i);
					}

				case "easeIn":

					if(i == (this._atties.innerSteps - 1)){
						to = from + this._easing.easeIn(i) - 1;
					}else{
						to = from + this._easing.easeIn(i);
					}
			}
			var tempGridElement = new PolyluxGrid(from, to, i);

			this._gridElements.push(tempGridElement);


		}

		//trace(_gridElements);

		// CHECK IF SCROLL POS IS IN SECTION
		this._inView = this.checkInView();
        this._isInViewPort = this.isInViewport();

        //trace("---------------------------------------------------------");
        //trace(_moduleElement);

        //trace("_isInViewPort: " + _isInViewPort);

        //trace("before _passedOnce");
        if(this._passedOnce == false){
            //trace("after _passedOnce");

            //trace("before _inView");
            if(this._inView != null){

                this._stepIndex = this._inView;

                this._pixelIndex = Math.floor(this._viewLine - this._startYPos);

                //trace("before isSilent");
                if(!this._atties.isSilent){
                //trace("after isSilent");

                    switch(this._atties.classHandling){

                        case "replace":
                            this._domClassHandler.add(this._moduleElement, [this._atties.stepPrefix + "-" + (this._stepIndex)]);

                        case "append":
                            this._domClassHandler.append(this._moduleElement, this._atties.stepPrefix, (this._stepIndex), this._atties.innerSteps);
                    }
                }

                this._state = 0;

                if(this._atties.isDemo == true){
                    this._demoGrid.redraw(this._gridElements, this._pixelIndex, this._stepIndex);
                }

            }else{

                //trace("else _inView");

                //if(_lastStep != _stepIndex || (_pixelIndex > 0 && _pixelIndex < _moduleElement.offsetHeight)){

                if(this._isInViewPort == true || this._initialPass != true){
                    //trace("before viewline is smaller");

                    this._initialPass = true;

                    if (this._viewLine < this._startYPos){
                        //trace("after viewline is smaller");
                        //trace(_id + " section is under the viewline");
                        //trace(_id + " y smaller start");
                        switch(this._atties.classHandling){

                            case "replace":
                                this._domClassHandler.add(this._moduleElement, [this._atties.stepPrefix + "-1", "above"]);

                            case "append":
                                this._domClassHandler.add(this._moduleElement, [this._atties.stepPrefix + "-1", "above"]);
                        }

                        this._pixelIndex = 0;
                        this._stepIndex = 1;

                        this._state = -1;

                        if(this._atties.isDemo == true){
                            this._demoGrid.redraw(this._gridElements, this._pixelIndex, this._stepIndex);
                        }
                    }else if(this._viewLine > this._endYPos){
                        //trace("after endPos is smaller");
                        //trace(_id + " section is over the viewline");

                        if(this._atties.onlyOnce == true){
                            this._passedOnce = true;
                        }

                        //trace("_atties.classHandling: " + _atties.classHandling);

                        switch(this._atties.classHandling){

                            case "replace":
                                this._domClassHandler.add(this._moduleElement, [this._atties.stepPrefix + "-" + this._atties.innerSteps, "below"]);

                            case "append":
                                this._domClassHandler.append(this._moduleElement, this._atties.stepPrefix, this._atties.innerSteps, this._atties.innerSteps, "below");
                        }

                        this._pixelIndex = this._endYPos - this._startYPos;
                        this._stepIndex = this._atties.innerSteps;

                        this._state = 1;

                        if(this._atties.isDemo == true){
                            this._demoGrid.redraw(this._gridElements, this._pixelIndex, this._stepIndex);
                        }
                    }

                    this._lastStep = this._stepIndex;
                }else{

                    this._initialPass = false;
                }
            }

            if(this._eventManager.eventStepChange.length > 0){
                this._eventManager.executeEvent("stepchange", new PolyluxStepEvent(this._stepIndex, this._atties.innerSteps, this._state));
            }

            if(this._eventManager.eventPixelChange.length > 0){
                this._eventManager.executeEvent("pixelchange", new PolyluxPixelEvent(this._pixelIndex, this._moduleElement.offsetHeight, this._state));
            }

        }



		//trace("_inView: " + _inView);


	}

    isInViewport(){

        var startViewport = (this._startYPos > window.pageYOffset && this._startYPos < (window.pageYOffset + window.innerHeight));
        var endViewport = (this._endYPos > window.pageYOffset && this._endYPos < (window.pageYOffset + window.innerHeight));
        return (startViewport == true || endViewport == true);
    }

	checkInView(){

		for(var i = 0; i < this._gridElements.length; i++){

			if (this._gridElements[i].checkIfActive(this._viewLine)){
				return this._gridElements[i].id + 1;
			}
		}

		return null;
	}

    offsetTop(element){

        var rect = element.getBoundingClientRect();

        var scrollTop = 0;
        if(window.pageYOffset != null){
            scrollTop = window.pageYOffset;
        }else{
            scrollTop = window.document.documentElement.scrollTop;
        }
        return rect.top + scrollTop;
    }

    addEventListener(eventName, func){

        //console.log("1* Event Name", eventName);
        this._eventManager.addEventListener(eventName, func);
    }

    removeEventListener(eventName, func){

        this._eventManager.removeEventListener(eventName, func);
    }

    // GETTER SETTER

    get id(){

        return this._id;
    }

    get isInViewPort() {

        return this._isInViewPort;
    }
}

export {PolyluxSection};





