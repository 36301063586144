import { PolyluxError } from '../error/PolyluxError.js';

class AttyHelper {

	_viewLineOffsetXs = 0.5;

    _viewLineOffsetSm = 0.5;

    _viewLineOffsetMd = 0.5;

    _viewLineOffsetLg = 0.5;

	_innerSteps = 10;

	_stepPrefix = "step";

	_isSilent = false;

    _onlyOnce = false;

	_easing = "linear";

    _classHandling = "replace";

	_isDemo = false;

	_demoColor = "#c00";

	_lineOffset;

	constructor(element = null){

		if(element != null){

			var rawInnerSteps = element.getAttribute("data-polylux-steps");
			var tempInnerSteps = parseInt(rawInnerSteps);
			if(tempInnerSteps != null){
				this._innerSteps = tempInnerSteps;
			}

			var rawOffsetXs = element.getAttribute("data-polylux-viewOffset-xs");
			var tempOffsetXs = parseFloat(rawOffsetXs);
			if(tempOffsetXs != null){
				this._viewLineOffsetXs = tempOffsetXs;
			}

			var rawOffsetSm = element.getAttribute("data-polylux-viewOffset-sm");
			var tempOffsetSm = parseFloat(rawOffsetSm);
			if(tempOffsetSm != null){
				this._viewLineOffsetSm = tempOffsetSm;
			}

			var rawOffsetMd = element.getAttribute("data-polylux-viewOffset-md");
			var tempOffsetMd = parseFloat(rawOffsetMd);
			if(tempOffsetMd != null){
				this._viewLineOffsetMd = tempOffsetMd;
			}

			var rawOffsetLg = element.getAttribute("data-polylux-viewOffset-lg");
			var tempOffsetLg = parseFloat(rawOffsetLg);
			if(tempOffsetLg != null){
				this._viewLineOffsetLg = tempOffsetLg;
			}

			var rawStepPrefix = element.getAttribute("data-polylux-prefix");
			if(rawStepPrefix != null){
				this._stepPrefix = rawStepPrefix;
			}

			var rawSilent = element.getAttribute("data-polylux-silent");
			if(rawSilent == "true"){
				this._isSilent = true;
			}

			var rawEasing = element.getAttribute("data-polylux-easing");
			if (rawEasing != null){
				//trace("rawEasing: " + rawEasing);
				if(rawEasing == "linear" || rawEasing == "easeOut" || rawEasing == "easeIn"){
					this._easing = rawEasing;
				}else{

					PolyluxError.display("easing parameter '" + rawEasing + "' is unknown");
				}
			}

			var rawClassHandling = element.getAttribute("data-polylux-classadd");
			if(rawClassHandling != null){
				this._classHandling = rawClassHandling;
			}

			var rawOnlyOnce = element.getAttribute("data-polylux-onlyonce");
			if(rawOnlyOnce == "true"){
				this._onlyOnce = true;
			}

			var rawDemo = element.getAttribute("data-polylux-demo");
			if(rawDemo == "true"){
				this._isDemo = true;
			}

			var rawDemoColor = element.getAttribute("data-polylux-demo-color");
			if(rawDemoColor != null){
				this._demoColor = rawDemoColor;
			}
		}
	}

    setValuesFromOptions(options){

        if(Reflect.has(options, 'prefix') == true){
            this._stepPrefix = options['prefix'];
        }

        if(Reflect.has(options, 'steps') == true){
            this._innerSteps = options['steps'];
        }

        if(Reflect.has(options, 'viewOffsetXs') == true){
            this._viewLineOffsetXs = options['viewOffsetXs'];
        }

        if(Reflect.has(options, 'viewOffsetSm') == true){
            this._viewLineOffsetSm = options['viewOffsetSm'];
        }

        if(Reflect.has(options, 'viewOffsetMd') == true){
            this._viewLineOffsetMd = options['viewOffsetMd'];
        }

        if(Reflect.has(options, 'viewOffsetLg') == true){
            this._viewLineOffsetLg = options['viewOffsetLg'];
        }

        if(Reflect.has(options, 'easing') == true){

			var tempEasing = options['easing'];
			//trace("tempEasing: " + tempEasing);
			if(tempEasing == "linear" || tempEasing == "easeOut" || tempEasing == "easeIn"){
				this._easing = tempEasing;
			}else{

				PolyluxError.display("easing parameter '" + tempEasing + "' is unknown");
			}
        }

        if(Reflect.has(options, 'classadd') == true){
            this._classHandling = options['classadd'];
        }

        if(Reflect.has(options, 'issilent') == true){
            this._isSilent = options['issilent'];
        }

        if(Reflect.has(options, 'onlyOnce') == true){
            this._onlyOnce = options['onlyOnce'];
        }

        if(Reflect.has(options, 'isDemo') == true){
            this._isDemo = options['isDemo'];
        }

        if(Reflect.has(options, 'demoGridColor') == true){
            this._demoColor = options['demoGridColor'];
        }
    }

	get viewLineOffsetXs(){
		return this._viewLineOffsetXs;
	}

	set viewLineOffsetXs(newFloat){
		this._viewLineOffsetXs = newFloat;
	}

	get viewLineOffsetSm(){
		return this._viewLineOffsetSm;
	}

	set viewLineOffsetSm(newFloat){
		this._viewLineOffsetSm = newFloat;
	}

	get viewLineOffsetMd(){
		return this._viewLineOffsetMd;
	}

	set viewLineOffsetMd(newFloat){
		this._viewLineOffsetMd = newFloat;
	}

	get viewLineOffsetLg(){
		return this._viewLineOffsetLg;
	}

	set viewLineOffsetLg(newFloat){
		this._viewLineOffsetLg = newFloat;
	}

	get innerSteps(){
		return this._innerSteps;
	}

	set innerSteps(newInt){
		this._innerSteps = newInt;
	}

	get stepPrefix(){
		return this._stepPrefix;
	}

	set stepPrefix(newString){
		this._stepPrefix = newString;
	}

	get isSilent(){
		return this._isSilent;
	}

	set isSilent(newBool){
		this._isSilent = newBool;
	}

	get easing(){
		return this._easing;
	}

	set easing(newString){
		this._easing = newString;
	}

	get classHandling(){
		return this._classHandling;
	}

	set classHandling(newString){
		this._classHandling = newString;
	}

	get onlyOnce(){
		return this._onlyOnce;
	}

	set onlyOnce(newBool){
		this._onlyOnce = newBool;
	}

	get isDemo(){
		return this._isDemo;
	}

	set isDemo(newBool){
		this._isDemo = newBool;
	}

	get lineOffset(){
		return this._lineOffset;
	}

	set lineOffset(newFloat){
		this._lineOffset = newFloat;
	}

	get demoColor(){
		return this._demoColor;
	}

	set demoColor(newColor){
		this._demoColor = newColor;
	}
}

export {AttyHelper};