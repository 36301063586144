class PolyluxEasing {

	_steps;

	_start;

	_end;

	constructor(steps, startPosition, endPosition){

		this._steps = steps;
		this._start = startPosition;
		this._end = endPosition;
	}

	linear(index){
		//trace("d: " + (_end - _start) / _steps);
		return (this._end - this._start) / this._steps;
	}

	easeOut(index){

		var factors = [];
        var value = 1;
        var potencial = 1;
		var factorTotal = 0;

        for(var i = 0; i < this._steps; i++){

            if(i > 0){

				value = (i + 1) + potencial;
			}

			factors.push(value);

			factorTotal += value;
        }

        return  ((this._end - this._start) / factorTotal) * factors[index];
	}

	easeIn(index){

		var factors = [];
        var value = 1;
        var potencial = 1;
		var factorTotal = 0;

        for(var i = 0; i < this._steps; i++){

            if(i > 0){

				value = (i + 1) + potencial;
			}

			factors.push(value);

			factorTotal += value;
        }

		factors.reverse();

        return  ((this._end - this._start) / factorTotal) * factors[index];
	}

	get start(){

		return this._start;
	}

	set start(value){

		this._start = value;
	}

	get end(){

		return this._end;
	}

	set end(value){

		this._end = value;
	}

}

export {PolyluxEasing};