import { HeaderHide } from "./helper/HeaderHide.js";
import { MobileNav } from "./helper/MobileNav.js";
import { SlideXSwiper } from "./modules/SlideXSwiper.js";
import { Polylux } from "./polylux/Polylux.js";


window.de = window.de || {};
window.de.wieland = window.de.wieland || {};

window.de.wieland.MainApp = class {

    static main(){

        let polylux = new Polylux();

        // Mobile Nav
        let headerMobileButtonWrapperElement = document.querySelector(".header__inner__menu");
        new MobileNav(headerMobileButtonWrapperElement);

        // Header hide on scroll
        let headerElement = document.querySelector(".header");
        new HeaderHide(headerElement, polylux);

        // Swiper
        let contentSwiperElements = [];
        contentSwiperElements = document.querySelectorAll(".productSwiper");

        for (let c5 = 0; c5 < contentSwiperElements.length; c5++) {

            let slides = contentSwiperElements[c5].querySelectorAll(".swiper-slide");

            let buttonPrev = contentSwiperElements[c5].querySelector(".swiper-button-prev");

            let buttonNext = contentSwiperElements[c5].querySelector(".swiper-button-next");

            if(slides.length > 1){

                let tempSwiper = new Swiper(contentSwiperElements[c5], {
                    slidesPerView: 1,
                    loop: true,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true,
                    },
                });
            }else {

                buttonPrev.style.display = 'none';
                buttonNext.style.display = 'none';
            }
            //console.log(tempSwiper);

        }
    }
}

de.wieland.MainApp.main();